.gifts {
  margin-top: 60px;
  margin-bottom: 60px;
}

.gifts__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  position: relative;

  padding: 30px;
  background: #1645a9;
  border-radius: 30px;

  width: 100%;

  @media (max-width: 430px) {
    padding: 10px;
  }
}

.gifts__wrapper > img {
  position: absolute;
  top: 0%;

  &:first-child {
    left: 0;
    top: 35%;
  }

  &:last-child {
    right: 0;

    @media (max-width: 670px) {
      top: 10%;
    }

    @media (max-width: 485px) {
      top: 15%;
    }
  }
}

.gifts__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  gap: 20px;

  h3 {
    font-size: 24px;
  }

  h2 {
    font-size: 48px;
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;

    @media (max-width: 670px) {
      font-size: 32px;
      text-align: center;
    }

    @media (max-width: 430px) {
      font-size: 28px;
    }
  }

  @media (max-width: 670px) {
    z-index: 2;
  }
}

.gifts__tv {
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }

  p {
    font-size: 20px;
    font-family: "Montserrat-sb", sans-serif;
    font-weight: 600;
  }

  @media (max-width: 670px) {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 20px;
  }
}
