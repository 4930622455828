.footer {
  margin-top: 60px;
  padding: 40px;
  background: var(--color-blue-sec);

  @media (max-width: 430px) {
    padding: 10px;
  }
}

.footer__left {
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    text-decoration: underline;
  }

  @media (max-width: 1190px) {
    h3 {
      text-align: center;
      font-size: 26px;
    }
  }
}

.footer__left > a > img {
  @media (max-width: 450px) {
    width: 100%;
  }
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 10px;
  }
}

.footer__organizators {
  width: 400px;
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  p {
    a {
      text-decoration: underline;
    }
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.footer__copyright {
  font-size: 10px;
  margin-top: 40px;
}
