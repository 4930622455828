.best_loto {
  margin-bottom: 60px;
}

.best__loto__wrapper > h2 {
  font-size: 32px;
  font-family: "Montserrat-bd", sans-serif;
  margin-bottom: 40px;

  @media (max-width: 430px) {
    font-size: 28px;
  }
}

.best__loto__slide_1 {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1250px) {
    flex-direction: column;
    gap: 20px;
  }
}

.best__loto__logo {
  p {
    font-size: 16px;
  }
}

.best__loto__slide {
  width: 400px;
  height: 470px;
  background: rgba(255, 255, 255, 0.15);
  padding: 25px;
  border-radius: 30px;

  @media (max-width: 1250px) {
    width: 100%;
  }

  // @media (max-width: 1190px) {
  //   width: 95%;
  // }

  // @media (max-width: 520px) {
  //   width: 90%;
  // }

  // @media (max-width: 430px) {
  //   width: 83vw;
  // }
}

.best__loto__top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 20px;
  font-size: 32px;
  font-family: "Montserrat-bd", sans-serif;
  font-weight: 700;

  @media (max-width: 520px) {
    font-size: 24px;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  button {
    background: none;
    cursor: pointer;
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 20px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.best__loto__stats {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:first-child {
      font-size: 14px;

      @media (max-width: 520px) {
        font-size: 12px;
      }
    }

    p:last-child {
      font-size: 14px;
      font-family: "Montserrat-sb", sans-serif;
      font-weight: 600;

      @media (max-width: 520px) {
        font-size: 12px;
      }

      @media (max-width: 450px) {
        font-size: 12px;
      }
    }
  }
}

.best__loto__top {
  position: relative;
}

.best__loto__slide__info {
  position: absolute;
  padding: 10px;
  background: var(--color-white);
  right: 5%;
  top: 10%;
  border-radius: 20px;

  a {
    margin-top: 0 !important;
    width: auto !important;
    background: none !important;
    color: var(--color-black) !important;
    border: none !important;
    padding: 0 !important;
    font-size: 16px;
    text-decoration: underline;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
}

.best__loto__slide {
  a {
    margin-top: 40px;
    display: block;
    width: 100%;
    background: #4c84ff;
    color: var(--color-white);
    text-align: center;
    border-radius: 30px;
    padding: 20px;
  }
}

.best__loto__slide_2 {
  margin-top: 60px;
}

@keyframes animateOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.best_loto__modal {
  width: 100vw;
  height: 100vh;

  position: fixed;

  background: rgba(0, 0, 0, 0.5);

  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 9999;

  animation-name: animateOpacity;
  animation-duration: 0.2s;
}

.best_loto__modal__container {
  position: relative;

  width: 800px;
  height: auto;

  background: var(--color-white);
  border-radius: 20px;
  padding: 20px;

  color: var(--color-black);

  @media (max-width: 930px) {
    width: 80%;
  }

  @media (max-width: 520px) {
    font-size: 12px;
  }
}

.best_loto__modal_close {
  position: absolute;
  top: -20%;
  left: 100%;

  height: 40px;
  width: 40px;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 520px) {
    top: -10%;
    left: 95%;
  }

  &::before {
    content: "";

    position: absolute;

    transform: rotate(45deg);

    width: 40px;
    height: 2px;

    background: var(--color-white);
  }

  &::after {
    content: "";

    position: absolute;

    transform: rotate(-45deg);

    width: 40px;
    height: 2px;

    background: var(--color-white);
  }
}

.best__loto__logo {
  width: 100px;
  height: 100px;
  background: var(--color-white);
  border-radius: 50%;
}
