.winners__wrapper {
  h2 {
    margin-bottom: 40px;
    font-size: 32px;
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;

    @media (max-width: 430px) {
      font-size: 28px;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1190px) {
      flex-direction: column;
      gap: 20px;
    }
  }
}

.winner__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .winners__image {
    img {
      width: 320px;
      height: auto;
    }
  }

  .winners__sum {
    background: #4c84ff;
    padding: 10px 40px;
    border-radius: 30px;

    p {
      font-size: 32px;
      font-family: "Montserrat-bd", sans-serif;
      font-weight: 700;

      @media (max-width: 430px) {
        font-size: 24px;
      }
    }
  }
}

.winner__item > p {
  font-size: 24px;
  font-family: "Montserrat-bd", sans-serif;
  font-weight: 700;

  @media (max-width: 430px) {
    font-size: 20px;
  }
}

.winners__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;

  button {
    background: none;

    img {
      width: 50px;
      height: auto;
    }
  }
}
