.faq {
  margin-top: 70px;
  background: var(--color-blue-sec);
  padding: 60px;
  overflow: hidden;

  @media (max-width: 880px) {
    padding: 10px;
  }

  @media (max-width: 430px) {
    padding: 10px;
  }
}

.faq__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.faq__right {
  position: absolute;
  top: -30%;
  right: 0;

  img {
    width: 400px;
    height: auto;
  }

  @media (max-width: 1120px) {
    display: none;
  }
}

.faq__left {
  padding: 30px;
  background: var(--color-blue);
  width: 520px;
  border-radius: 40px;

  @media (max-width: 1120px) {
    width: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      padding: 10px 0;
      border-bottom: 1px solid var(--color-white);
      cursor: pointer;

      .faq__item {
        p {
          font-size: 20px;
        }
      }
    }
  }
}

.faq__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
