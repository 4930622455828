.head {
  margin-top: 60px;

  @media (max-width: 1190px) {
    margin-top: 20px;
  }
}

.head__image_2 {
  display: none;

  @media (max-width: 1190px) {
    display: block;
  }
}

.head__image_con {
  @media (max-width: 1190px) {
    // width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.head__image_1 {
  display: block;

  @media (max-width: 1190px) {
    display: none;
  }
}

.head__image {
  width: 505px;
  height: 505px;
  border-radius: 50%;
  background: var(--color-white);
  overflow: hidden;

  @media (max-width: 1190px) {
    width: 505px;
    height: 505px;
  }

  @media (max-width: 670px) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 520px) {
    width: 350px;
    height: 350px;
  }

  @media (max-width: 400px) {
    width: 300px;
    height: auto;
  }

  img {
    margin-top: 100px;
    margin-left: 5px;

    width: 90%;
    height: 90%;

    @media (max-width: 520px) {
      margin-top: 40px;
      margin-left: 0;
    }
  }

  position: relative;

  @media (max-width: 1190px) {
    background: var(--color-white);
  }
}

.head__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1190px) {
    flex-direction: column;
    justify-content: center;
  }
}

.head__main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  
  width: 50vw;

  @media (max-width: 1190px) {
    width: 100vw;

    ul {
      order: 1;
    }

    a {
      order: 0;
    }
  }
}

.head__left {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1190px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 48px;
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;

    @media (max-width: 670px) {
      font-size: 28px;
      margin-left: 10px;
    }
  }

  ul {
    display: flex;
    gap: 10px;

    @media (max-width: 670px) {
      flex-wrap: wrap;
      margin-left: 10px;
    }

    @media (max-width: 400px) {
      gap: 10px;
    }

    li {
      list-style-type: disc;
      margin-left: 20px;
      font-size: 20px;
      font-family: "Montserrat-md", sans-serif;
      font-weight: 500;
    }
  }

  a {
    padding: 20px;
    background: var(--color-red);
    width: 80%;
    border-radius: 40px;
    text-align: center;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 1190px) {
      width: 95%;
      margin-left: 10px;
    }
  }
}
