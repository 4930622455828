@font-face {
  font-family: "Montserrat";
  src: local("../assets/fonts/Montserrat-Regular.ttf"),
    url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-sb";
  src: local("../assets/fonts/Montserrat-SemiBold.ttf"),
    url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-bd";
  src: local("../assets/fonts/Montserrat-Bold.ttf"),
    url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}
